<template>
  <div class="Table EMTable">
    <div class="Table__toolbar">
      <div class="Table__toolbar-search">
        <search-input :value.sync="searchInput" />
        <div class="EMTable__toolbar-department">
          <common-select
            :options="optionsDepartments"
            placeholder="部署"
            :value.sync="selectedDepartment"
            :on-change="handleChangeSelect"
          />
        </div>
        <div class="EMTable__toolbar-saas">
          <common-select
            :options="optionsSaasState"
            placeholder="利用状態"
            :value.sync="selectedSaasState"
            :on-change="handleChangeSelect"
          />
        </div>
      </div>
      <div class="Table__toolbar-buttons">
        <add-new-button v-if="hasAddButton" class-name="add-button" :func="onCreate" />
      </div>
    </div>
    <div class="table-responsive">
      <b-table
        ref="em-table"
        :items="items"
        :fields="fields"
        sort-by="user_code"
        thead-class="Table__header"
        tbody-tr-class="Table__row"
        hover
      >
        <template #head()="data">
          <div v-if="!!data.label" class="header">
            <div class="header--value">
              <span>{{ data.label }}</span>
            </div>
            <div v-if="data.field.sortable" class="header--sort">
              <SortIcon />
            </div>
          </div>
          <div v-else class="action">
            {{ '' }}
          </div>
        </template>
        <template v-if="hasAddButton" #cell(actions)="data">
          <div class="cell">
            <img
              v-if="!hideEditIcon(data.item)"
              src="@/assets/icons/ic_edit.svg"
              alt="edit-icon"
              @click.stop.prevent="onEdit(data.item)"
            />
            <img
              v-if="!hideDeleteIcon(data.item)"
              src="@/assets/icons/ic_delete.svg"
              alt="delete-icon"
              @click.stop.prevent="onDelete(data.item.id)"
            />
          </div>
        </template>
        <template #cell(role_id)="data">
          <div>
            {{ getRoleLabel(data.item.role_id) }}
          </div>
        </template>
        <template #cell(avatar)="data">
          <img :src="data.value || defaultAvatar" alt="avatar-image" />
        </template>
        <template #cell(status)="data">
          <div :class="getClassStatus(data.item.status)">
            {{ getStatusLabel(data.item.status) }}
          </div>
        </template>
        <template #cell(name)="data">
          <div class="name" @click="onRowSelected(data.item.id)">
            {{ data.value }}
          </div>
        </template>
      </b-table>
    </div>
    <div class="Table__paging">
      <table-paging
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
        :total="total"
        :options-paging="optionsPaging"
      />
    </div>
    <slot name="dialog" />
  </div>
</template>

<script>
import { employeeManagementService } from '@/services/employee-management-service'
import SearchInput from '@/components/commons/common-search-input'
import AddNewButton from '@/components/add-new-button'
import TablePaging from '@/components/commons/common-table-paging'
import SortIcon from '@/components/sort-icon'
import { mapGetters } from 'vuex'
import get from 'lodash-es/get'
import { DEFAULT_COMPANY_LOGO, MEMBER_STATUS, OWNER } from '@/utils/constants'
import CommonSelect from '@/components/commons/common-select'

export default {
  name: 'EMTable',
  components: { SearchInput, AddNewButton, TablePaging, SortIcon, CommonSelect },
  props: {
    showEditDialog: {
      type: Function,
      default: () => {
        return {}
      },
    },
    showAddDialog: {
      type: Function,
      default: () => {
        return {}
      },
    },
    showDeleteDialog: {
      type: Function,
      default: () => {
        return {}
      },
    },
    hasAddButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      searchInput: '',
      fields: employeeManagementService.getFields(),
      items: [],
      total: 0,
      currentPage: 1,
      perPage: 10,
      optionsPaging: [10, 50, 100],
      optionsDepartments: [],
      selectedDepartment: '',
      selectedSaasState: '',
      defaultAvatar: DEFAULT_COMPANY_LOGO, // use this logo as default
    }
  },
  computed: {
    ...mapGetters('commonSettings', ['user_list_status', 'roles', 'saas_fillter']),
    ...mapGetters('user', ['currentUser']),
    optionsSaasState() {
      const result = []
      Object.keys(this.saas_fillter).forEach((key) => {
        if (key !== '') {
          result.push({
            value: key,
            text: this.saas_fillter[key],
          })
        }
      })
      return result
    },
  },
  watch: {
    searchInput(newValue) {
      this.handleValueChange(newValue)
    },
    perPage() {
      this.handleValueChange(this.searchInput)
    },
    currentPage() {
      this.handleValueChange(this.searchInput)
    },
  },
  async created() {
    this.selectedSaasState = this.$route.params.saasConnected || ''
    await this.reloadTable()
    this.optionsDepartments.push(...(await employeeManagementService.getDepartments()))
  },
  methods: {
    onCreate() {
      this.showAddDialog(() => this.reloadTable(true))
    },
    onEdit(rowData) {
      this.showEditDialog(rowData, () => this.reloadTable(true))
    },
    onDelete(id) {
      this.showDeleteDialog(id, () => this.reloadTable(true))
    },
    getRoleLabel(roleId) {
      return get(this.roles.filter((status) => status.id === roleId)[0], 'name', '')
    },
    getStatusLabel(id) {
      return get(this.user_list_status.filter((status) => status.id === id)[0], 'name', '')
    },
    getClassStatus(id) {
      if (id === MEMBER_STATUS.ACTIVE) {
        return 'cell__status cell__status-active'
      }
      return 'cell__status cell__status-inactive'
    },
    onRowSelected(id) {
      this.$router.push({
        path: '/customer/employee-detail',
        name: 'employee-detail',
        params: {
          id,
        },
      })
    },
    async reloadTable(isMaster = false) {
      // reload tables data
      this.searchInput = ''
      const { items, total } = await employeeManagementService.getItems(
        this.perPage,
        this.currentPage,
        this.searchInput,
        this.selectedDepartment,
        this.selectedSaasState,
        isMaster
      )
      this.items = items
      this.total = total
    },
    async handleValueChange(keyword) {
      const { items, total } = await employeeManagementService.getItems(
        this.perPage,
        this.currentPage,
        keyword,
        this.selectedDepartment,
        this.selectedSaasState
      )
      this.items = items
      this.total = total
    },
    async handleChangeSelect() {
      const { items, total } = await employeeManagementService.getItems(
        this.perPage,
        this.currentPage,
        this.searchInput,
        this.selectedDepartment,
        this.selectedSaasState
      )
      this.items = items
      this.total = total
    },
    hideDeleteIcon(item) {
      return item.id === this.currentUser.id || item.is_owner === OWNER.OK
    },
    hideEditIcon(item) {
      return item.id !== this.currentUser.id && item.is_owner === OWNER.OK
    },
  },
}
</script>
