<template>
  <Layout>
    <page-layout page-titles="従業員管理" :bread-crumb-items="breadcrumbItems">
      <template #table-content>
        <e-m-table
          :show-add-dialog="showAddDialog"
          :show-edit-dialog="showEditDialog"
          :show-delete-dialog="showDeleteDialog"
          :has-add-button="!isCustomerUser"
        >
          <template #dialog>
            <c-modal :id="dialogId" :title="dialogTitle" :buttons="dialogButtons">
              <e-m-form
                :form-data.sync="formData"
                :trigger-validate="triggerValidate"
                :is-valid.sync="isValid"
              />
            </c-modal>
            <delete-confirmation :id="dialogDeleteId" :confirm-delete-func="onDeleteConfirm" />
          </template>
        </e-m-table>
      </template>
    </page-layout>
  </Layout>
</template>

<script>
import Layout from '../../layouts/main/app'
import EMTable from '@/components/tables/employee-management-table'
import PageLayout from '@/components/commons/common-page-layout'
import CModal from '@/components/commons/common-modal'
import EMForm from '@/components/forms/employee-mangement-form'
import DeleteConfirmation from '@/components/modals/delete-confirmation-modal'
import { mapActions, mapGetters } from 'vuex'
import pick from 'lodash-es/pick'
import { employeeManagementService } from '@/services/employee-management-service'
import { ALERT_TYPE } from '@/utils/constants'
import { MESSAGES } from '@/utils/messages'
import { isHttpLink } from '@/utils/object-helpers'

/**
 * EmployeeManagementPage component
 */
export default {
  name: 'EmployeeManagementPage',
  components: {
    Layout,
    EMTable,
    PageLayout,
    CModal,
    EMForm,
    DeleteConfirmation,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: '部署・従業員管理',
          href: '#',
        },
        {
          text: '従業員',
          active: true,
        },
      ],
      dialogId: 'em-dialog',
      dialogDeleteId: 'del-dialog',
      dialogTitles: {
        ADD: {
          id: 1,
          text: '従業員新規登録',
        },
        EDIT: {
          id: 2,
          text: '従業員情報編集',
        },
        DELETE: {
          id: 3,
          text: '',
        },
      },
      dialogTitle: '',
      formData: {},
      onAddOrEditConfirm: () => {
        return {}
      },
      triggerValidate: false,
      isValid: false,
      requiredCreateKeys: [
        'user_code',
        'email',
        'name',
        'department_id',
        'position_id',
        'status',
        'avatar',
      ],
      requiredEditKeys: [
        'id',
        'user_code',
        'email',
        'name',
        'department_id',
        'position_id',
        'status',
        'avatar',
      ],
      buttonText: '',
    }
  },
  computed: {
    ...mapGetters('user', ['isCustomerUser']),
    dialogButtons() {
      return [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: this.buttonText,
          variant: 'primary',
          minWidth: 133,
          size: 'md',
          func: this.onAddOrEditConfirm,
        },
      ]
    },
  },
  methods: {
    ...mapActions('alert', ['displayAlert']),
    showEditDialog(rowData, editSuccessCallback) {
      this.dialogTitle = this.dialogTitles.EDIT.text
      this.formData = { ...rowData }
      this.buttonText = '更新'
      this.onAddOrEditConfirm = async () => {
        this.triggerValidate = !this.triggerValidate
        setTimeout(async () => {
          if (this.isValid) {
            this.callbackFn = editSuccessCallback
            await this.onUpdateConfirm()
          }
        }, 150)
      }
      this.$bvModal.show(this.dialogId)
    },

    showAddDialog(createSuccessCallback) {
      this.dialogTitle = this.dialogTitles.ADD.text
      this.formData = null
      this.buttonText = '保存'
      this.onAddOrEditConfirm = async () => {
        this.triggerValidate = !this.triggerValidate
        setTimeout(async () => {
          if (this.isValid) {
            const formData = pick(this.formData, this.requiredCreateKeys)
            if (isHttpLink(formData.avatar)) {
              delete formData.avatar
            }
            const { success, data } = await employeeManagementService.createItem(formData)
            if (!success) {
              const messages = this.getErrorAlert(data)
              this.showErrorMessages(messages)
              return
            }
            createSuccessCallback && createSuccessCallback()
            this.$bvModal.hide(this.dialogId)
            this.displayAlert({
              type: ALERT_TYPE.SUCCESS,
              messages: MESSAGES.COMMON.MSG01,
            })
          }
        }, 150)
      }
      this.$bvModal.show(this.dialogId)
    },

    showDeleteDialog(deleteId, deleteSuccessCallback) {
      this.deleteId = deleteId
      this.callbackFn = deleteSuccessCallback
      this.dialogTitle = this.dialogTitles.DELETE.text
      this.$bvModal.show(this.dialogDeleteId)
    },

    async onDeleteConfirm() {
      const result = await employeeManagementService.deleteItem(this.deleteId)
      if (result) {
        // delete success
        this.callbackFn && (await this.callbackFn())
        this.displayAlert({
          type: ALERT_TYPE.SUCCESS,
          messages: MESSAGES.COMMON.MSG05,
        })
      }
      this.$bvModal.hide(this.dialogDeleteId)
    },
    async onUpdateConfirm() {
      const formData = pick(this.formData, this.requiredEditKeys)
      if (isHttpLink(formData.avatar)) {
        delete formData.avatar
      }
      const { success, data } = await employeeManagementService.editItem(formData)
      if (!success) {
        const messages = this.getErrorAlert(data)
        this.showErrorMessages(messages)
        return
      }
      this.callbackFn && this.callbackFn()
      this.$bvModal.hide(this.dialogId)
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.COMMON.MSG02,
      })
    },

    getErrorAlert(items) {
      const result = []
      if (JSON.stringify(items).indexOf('user_code') !== -1) {
        result.push(MESSAGES.CUSTOMER_MT.KH08)
      }
      if (JSON.stringify(items).indexOf('email') !== -1) {
        result.push(MESSAGES.CUSTOMER_MT.KH09)
      }
      if (result.length === 0) {
        result.push(MESSAGES.COMMON.MSG15)
      }
      return result
    },

    showErrorMessages(messages) {
      messages.forEach((mes) => {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: mes,
        })
      })
    },
  },
}
</script>
